import { Component } from '@angular/core';
import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';

@Component({
   selector: 'app-root',
   templateUrl: 'app.component.html',
   styleUrls: ['app.component.scss']
})
export class AppComponent {
   constructor(
      private platform: Platform,
      private splashScreen: SplashScreen,
      private statusBar: StatusBar,
      private storage: Storage,
      public navCtrl: NavController
   ) {
      this.initializeApp();
   }

   initializeApp() {
      this.platform.ready().then(() => {
         this.statusBar.styleDefault();
         this.splashScreen.hide();
      });

      // Verifica si esta logeado
      this.storage.get('logeado').then((res) => {
         if (res === null) {
            this.navCtrl.navigateRoot('/intro');
         } else {
            this.navCtrl.navigateRoot('/home');
         }
      });
   }

}
