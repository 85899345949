import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/timeout';

@Injectable()
export class Accesobackend {
    // URL backend api JSON LOCALHOST
    // server = 'http://localhost:8888/maestrosunicosboliviabackend/';
    // URL backend api JSON LOCALHOST
    server = 'https://maestrosunicosbolivia.cybermaq.com/maestrosunicosboliviabackend/';

    constructor(
        public http: HttpClient
    ) {}

    postData(body, file) {
        const headers = new HttpHeaders ({
            'Content-type': 'application/json; charset=UTF-8'
        });
        const options = {
            headers
        };
        return this.http.post(this.server + file, JSON.stringify(body), options)
        .timeout(59000) // 59 seconds
        .map(res => res);
    }
}
