import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { NavController, ActionSheetController } from '@ionic/angular';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit{

  constructor(
      public storage: Storage,
      public navCtrl: NavController,
      public actionSheetCtrl: ActionSheetController
   )
    { }

    ngOnInit() {}
    /*
    datosDB: any;
    nombresus: string;
    apellidosus: string;
    cius: string;
    rol: string;
    idrol: string;
    carrera: string;
    users: any = [];
    limit = 15; // limite de datos a mostrar
    start = 0;

   ngOnInit() {
      this.storage.get('logeado').then((res) => {
         if (res != null) { // verifica si hay login
               if (res.rolus === '1') { this.rol = 'Administrador'; }
               if (res.rolus === '2') { this.rol = 'Jefe de carrera'; }
               if (res.rolus === '3') { this.rol = 'Secretaría'; }
               if (res.rolus === '4') { this.rol = 'Docente'; }
               if (res.rolus === '5') { this.rol = 'Estudiante'; }
               if (res.carreraus === 'INF') { this.carrera = 'Informática Industrial'; }
               this.datosDB = res;
               console.log(res);
               this.nombresus = res.nombresus;
               this.idrol = res.rolus;
               this.cius = res.cius;
               this.apellidosus = res.apellidosus;
               this.start = 0;
               this.users = [];
            // this.loadUsers ();
         } else {
            this.navCtrl.navigateRoot('/login');
         }
      });
   } */

}
